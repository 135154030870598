
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import React, { useState } from "react";

function App() {
  const [data, setData] = useState({
    email: ""
  });
  const [msg, setMsg] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: data.email
    };
    axios.post("https://api.tones.studio/v0/subscribe", userData).then((response) => {
      if (response.status === 200 || response.status === 201){
        setMsg("Thank you, we'll be in touch...")
      }else{
        setMsg("Could not reach our backend, please try again later...")
      }
    });
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };
  return (
  <div className="App" >
  <div class="header">AI-driven DAW, voice prompts, separation, auto-tune, composition, cloning, mastering, collaborate and share on social</div>
     
      <header className="App-header">
        <img src="https://media.tones.studio/img/AppIcon_t.png" alt="Tones" class="icon" />
        <div class="logo0">tones</div>
        <div class="panel">
            <div class="teaser">AI-driven Digital Audio Workstation</div>
            <div class="details">Apple iPhone, iPad and Silicon Mac</div>
            <br/>
            <div class="action">
            Sign up for our upcoming beta release:<br/>
            <form onSubmit={handleSubmit}>
            <span>
               <input type="email" name="email" value={data.email} onChange={handleChange} class="inp"  placeholder="email"/>
               &nbsp;<button class="sendBtn" type="submit" text="Send" >send</button>
            </span>
            </form>
            <div class="responseMsg">{msg}</div>
            <br/>&nbsp;
            <br/>&nbsp;
            </div>
          </div>
            <div class="bg3">
           <video class ="myVideo"  autoPlay loop muted>
            <source src="https://media.tones.studio/video/future.mp4" type='video/mp4' />
            </video> 
            </div>
       </header>
      <div class="footer">v1.0.6 ©2024 - tones.studio TECH41 GmbH, Hamburg, contact: <a className ="alink" href="mailto:info@tones.studio">info@tones.studio</a></div>
    </div>
  );
}
export default App;
